import HttpService from './http.service';
import { getCookie } from 'shared_components/src/service/common.service';
import { GENERAL_COOKIES } from 'shared_components/src/common/constants';
import { isArray, isEmpty } from 'lodash';

const objectToQueryString = (obj: any, isStarted = false) => {
	Object.keys(obj).forEach((key) => isEmpty(obj[key]) && delete obj[key]);

	if (obj) {
		return Object.keys(obj)
			.map((key, id) => {
				if (isArray(obj[key])) {
					const elements = obj[key].join(',');
					return `${isStarted && id === 0 ? '?' : '&'}${encodeURIComponent(
						key
					)}=${encodeURIComponent(elements)}`;
				} else if (obj[key])
					return (
						(isStarted && id === 0 ? '?' : '&') +
						encodeURIComponent(key) +
						'=' +
						encodeURIComponent(obj[key])
					);
			})
			.join('');
	}

	return '';
};

type RUFCMatchingField = {
	key: string;
	ocrConfidence: number;
	match: string;
};

type RUFCMatchingFace = {
	status: string;
	faceId: string;
	similarity: number;
};

type RUFCMatchingIdentityOwner = {
	status: string;
	face: RUFCMatchingFace;
	fields: RUFCMatchingField[];
	email: {
		match: string;
	};
};

type RUFCMatchingIdDocument = {
	status: string;
	face: RUFCMatchingFace;
	fields: RUFCMatchingField[];
};

type RUFCMatchingFraudCheckRecord = {
	outcome: string;
	verificationId: string;
	documentId: string;
	noOfDocuments: number;
	processedOn: string;
	matchingTypes: string[];
	identityOwner: RUFCMatchingIdentityOwner;
	idDocument: RUFCMatchingIdDocument;
};

type RUFCMatchingRecord = {
	outcome: string;
	verificationId: string;
	documentId: string;
	noOfDocuments: number;
	matchingFraudCheckRecord: RUFCMatchingFraudCheckRecord;
};

type RUFCMatchingResponse = {
	matchedFraudCheckOutput: RUFCMatchingRecord[];
	count: number;
	limit: number;
	page: number;
	pageCount: number;
};

class KycApiService {
	constructor() {
		HttpService.init('');
	}

	getVerificationImageByUrl(verId: string, docId: string, side?: string) {
		const query = side ? `?pageId=${side}` : '';
		return HttpService.get(
			`/identity-verification/v2/verifications/${verId}/documents/${docId}/images${query}`
		);
	}

	getDocumentFields(docType: string, classificationCode: string) {
		return HttpService.get(
			`/v1/form-metadata/document-types/${docType}?classificationcode=${classificationCode}`
		);
	}

	/**
	 * Initialize
	 * @param token
	 */
	init(token?: string) {
		HttpService.init(token ?? '');
	}

	/**
	 * Get api url from configuration cookie
	 */
	getApiUrlFromCookie() {
		HttpService.getBaseUrlFromCookie();
	}

	/**
	 * API related to Configuration
	 */
	getConfiguration(url: string) {
		return HttpService.get(`${url}/config.json`, true, false);
	}

	getSettings() {
		return HttpService.get(`/v1/settings`);
	}

	updateTenantSettings(request) {
		return HttpService.put(`/v1/settings`, request);
	}

	/**
	 * APIs related to Verification
	 */
	getVerificationList(pageIndex: number, pageSize: number, columnSort: string, filter: Object) {
		return HttpService.get(
			`/identity-verification/v2/verifications?page=${pageIndex}&limit=${pageSize}&order=${columnSort}${objectToQueryString(
				filter
			)}`
		);
	}

	getVerificationDetail(id: string) {
		return HttpService.get(`/identity-verification/v2/verifications/${id}`);
	}

	getFaceImageByUrl(id: string) {
		return HttpService.get(`/identity-verification/v2/verifications/${id}/images/faces`);
	}

	updateVerificationDetails(id: string, req: any) {
		return HttpService.put(`/identity-verification/v2/verifications/${id}`, req);
	}

	sendInviteToCustomer(req: any, id?: string) {
		return HttpService.post(`/identity-verification/v2/verifications/${id ? id + '/' : ''}invite`, req);
	}

	deleteVerification(verId: string) {
		return HttpService.delete(`/identity-verification/v2/verifications/${verId}`);
	}

	generateInviteUrl(req: any) {
		return HttpService.post(`/identity-verification/v2/verifications/create`, req);
	}

	updateVerificationComplete(id: string, req: any) {
		return HttpService.post(`/identity-verification/v2/verifications/${id}/complete`, req);
	}

	getVerificationDocument(id: string, type: string) {
		return HttpService.get(`/identity-verification/v2/verifications/${id}/documents/${type}/images`);
	}

	/**
	 * APIs related to Users
	 */
	getCurrentUserDetails() {
		const userInfo = JSON.parse(getCookie(GENERAL_COOKIES.userInfo));

		if (userInfo) {
			return HttpService.get(`/v1/users/me`);
		} else {
			return new Promise((resolve) => {
				return resolve({});
			});
		}
	}

	createUser(req: any) {
		return HttpService.post(`/v1/users`, req);
	}

	updateUser(req: any, username: string) {
		return HttpService.put(`/v1/users/${username}`, req);
	}

	getUserDetail(username: string) {
		return HttpService.get(`/v1/users/${username}`);
	}

	getUsersList(pageIndex: number, pageSize: number, columnSort: string, filter: Object) {
		return HttpService.get(
			`/v1/users?page=${pageIndex}&limit=${pageSize}&order=${columnSort}${objectToQueryString(filter)}`
		);
	}

	getDeepLink(verificationID: string, tenantAlias: string) {
		return HttpService.get(
			`/identity-verification/v2/verifications/${verificationID}/deep-link/${tenantAlias}`,
			false,
			false
		);
	}

	/**
	 * Products
	 */
	getProducts() {
		return HttpService.get(`/v1/products`);
	}

	getProductDetail(code: string) {
		return HttpService.get(`/v1/products/${code}`);
	}

	downloadReport(id: string, type: string) {
		let url = '/report';

		if (type === 'AUS_BUSINESS_VERIFICATION_REPORT') {
			url = '/business-report';
		} else if (type === 'SANCTIONS_AND_PEP_REPORT') {
			url = '/sanctions-pep-report';
		} else if (type === 'FRAUD_CHECK_REPORT') {
			url = '/fraud-check-report';
		}
		return HttpService.get(`/identity-verification/v2/verifications/${id}${url}`);
	}

	getTenantInfo(alias: string) {
		return HttpService.get(`/tenant-management/v1/tenants/${alias}`);
	}

	updateTenant(data: any) {
		return HttpService.put(`/tenant-management/v1/tenants/${data.alias}`, data);
	}

	getTenantIntegrations() {
		return HttpService.get(`/v1/tenants/credentials`);
	}

	createNewAPIIntegrations(alias: string, requestData: any) {
		return HttpService.post(`/v1/tenants/credentials`, requestData);
	}

	updateAPIIntegrations(alias: string, requestData: any) {
		return HttpService.put(`/v1/tenants/credentials`, requestData);
	}

	deleteAPIIntegrations(alias: string, requestData: any) {
		return HttpService.delete(`/v1/tenants/credentials/${requestData.accessKey}/accesskey`);
	}

	getTenantBilling(alias: string) {
		return HttpService.get(`/tenant-management/v1/subscriptions/${alias}`);
	}

	updateTenantBilling(data: any) {
		return HttpService.put(`/tenant-management/v1/subscriptions/billing/update`, data);
	}

	updateCardDetails(alias: string, data: any) {
		return HttpService.put(`/tenant-management/v1/tenants/${alias}/payment`, data);
	}

	getTenantPlanInfo(productCode: string, planCode: string) {
		return HttpService.get(`/v1/products/${productCode}/plans/${planCode}`);
	}

	updateTenantPlanInfo(tenantAlias: string, data: any) {
		return HttpService.put(`/tenant-management/v1/tenants/${tenantAlias}/plan`, data);
	}

	cancelTenant(data: any) {
		return HttpService.put(`/tenant-management/v1/tenants/${data.tenantAlias}/cancel`, data);
	}

	activateTenant(data: any) {
		return HttpService.put(`/tenant-management/v1/tenants/${data.tenantAlias}/activate`, data);
	}

	getCountries() {
		return HttpService.get(`/v1/countries`);
	}

	/**
	 * Web Hooks
	 */

	getTenantWebhooks(alias: string) {
		return HttpService.get(`/webhook/v1/tenants/${alias}/webhook/subscriptions`);
	}

	deleteTenantWebhook(alias: string, subscriptionId: string) {
		return HttpService.delete(`/webhook/v1/tenants/${alias}/webhook/subscriptions/${subscriptionId}`);
	}

	/* 
		{
			"webhookType": "CALLBACK",
			"tenantAlias": "client",
			"description":"Callback integration",
			"url": "https://test.callback.url",
			"authenticationType": "BASIC",
			"authToken":"BASIC asjdhasdhoiahsdioahsidohasiod=="
		}
	*/

	createTenantWebhook(alias: string, data: any) {
		return HttpService.post(`/webhook/v1/tenants/${alias}/webhook/subscriptions`, data);
	}

	updateTenantWebhook(alias: string, subscriptionId: string, data: any) {
		return HttpService.put(`/webhook/v1/tenants/${alias}/webhook/subscriptions/${subscriptionId}`, data);
	}

	getWebhookTypes() {
		return HttpService.get(`/webhook/v1/webhooks/webhook-types`);
	}

	/**
	 * ID Health Check
	 *
	 **/

	submitIDHealthCheck(data: any) {
		const url = `${process.env.REACT_APP_SUBMISSION_BASE_URL}/v1/verifications/submit`;
		return HttpService.post(url, data, true);
	}

	getSupportedDocuments() {
		const url = `${process.env.REACT_APP_CLIENT_PUBLIC_BASE_URL}/v1/supported-documents`;
		return HttpService.get(url, true);
	}

	getBatchRefIDs(searchTerm: string = '') {
		return HttpService.get(
			`/identity-verification/v2/verifications/batch-ref-ids?search=${searchTerm}&page=1&limit=25`
		);
	}

	/**
	 * RUFC
	 */
	// Define types for the API response

	getRUFCMatchingRecords(
		verificationId: string,
		page: number = 1,
		limit: number = 10
	): Promise<RUFCMatchingResponse> {
		// Generate 20 mock records
		const allRecords = Array.from({ length: 17 }, (_, index) => ({
			outcome: ['FAIL', 'PASS', 'WARNING'][Math.floor(Math.random() * 3)],
			verificationId: verificationId,
			documentId: 'adccde35-ae3c-4b28-bbb8-34ab9638d741',
			noOfDocuments: Math.random() > 0.5 ? 1 : 2,
			matchingFraudCheckRecord: {
				outcome: ['FAIL', 'PASS', 'WARNING'][Math.floor(Math.random() * 3)],
				verificationId: `VID-${Math.random().toString(36).substr(2, 9)}`,
				documentId: `doc-${index + 1}`,
				noOfDocuments: Math.random() > 0.5 ? 1 : 2,
				matchingTypes: [
					['Same face, different PII', 'Same face, same PII', 'Different face, same PII'][
						Math.floor(Math.random() * 3)
					],
				],
				processedOn: new Date(Date.now() - Math.floor(Math.random() * 10000000000)).toISOString(),
				identityOwner: {
					status: 'MATCH',
					face: {
						status: ['FAIL', 'PASS', 'WARNING'][Math.floor(Math.random() * 3)],
						faceId: `face-${index + 1}`,
						similarity: Math.random(),
					},
					fields: [{ key: 'givenName', ocrConfidence: 0.99, match: 'MATCHED' }],
					email: { match: 'MATCH' },
				},
				idDocument: {
					status: 'MATCH',
					face: {
						status: ['FAIL', 'PASS', 'WARNING'][Math.floor(Math.random() * 3)],
						faceId: `face-${index + 1}`,
						similarity: Math.random(),
					},
					fields: [{ key: 'givenName', ocrConfidence: 0.99, match: 'MATCHED' }],
				},
			},
		}));

		// Calculate pagination
		const startIndex = (page - 1) * limit;
		const endIndex = startIndex + limit;
		const paginatedRecords = allRecords.slice(startIndex, endIndex);

		const mockResponse: RUFCMatchingResponse = {
			matchedFraudCheckOutput: paginatedRecords,
			count: allRecords.length,
			limit: limit,
			page: page,
			pageCount: Math.ceil(allRecords.length / limit),
		};

		return new Promise<RUFCMatchingResponse>((resolve) => {
			setTimeout(() => {
				resolve(mockResponse);
			}, 500);
		});
	}
}

export default new KycApiService();
